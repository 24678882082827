import { AnyAction } from 'redux';

export enum KeySetterActionType {
    SET_POPUPKEY = 'SET_POPUPKEY',
    REMOVE_POPUPKEY = 'REMOVE_POPUPKEY',
    SET_POPUPKEYNAME = 'SET_POPUPKEYNAME',
    REMOVE_POPUPKEYNAME = 'REMOVE_POPUPKEYNAME',
    SET_ISLOADING = 'SET_ISLOADING',
    REMOVE_ISLOADING = 'REMOVE_ISLOADING',
    SET_CLICKCOUNT = 'SET_CLICKCOUNT',
    REMOVE_CLICKCOUNT = 'REMOVE_CLICKCOUNT',
    REMOVE_FROM = 'REMOVE_FROM',
    SET_FROM = 'SET_FROM',
    // New action type for removing the key
}

export interface SetKeyAction extends AnyAction {
    type: KeySetterActionType.SET_POPUPKEY;
    key: any;
}

export interface RemoveKeyAction extends AnyAction {
    type: KeySetterActionType.REMOVE_POPUPKEY;
}

export interface SetKeyNameAction extends AnyAction {
    type: KeySetterActionType.SET_POPUPKEYNAME;
    name: any;
}

export interface RemoveKeyNameAction extends AnyAction {
    type: KeySetterActionType.REMOVE_POPUPKEYNAME;
}

export interface SetKeyLoadingAction extends AnyAction {
    type: KeySetterActionType.SET_ISLOADING;
    isLoading: any;
}

export interface RemoveKeyLoadingAction extends AnyAction {
    type: KeySetterActionType.REMOVE_ISLOADING;
}

export interface SetKeyClickCounAction extends AnyAction {
    type: KeySetterActionType.SET_CLICKCOUNT;
    countTimes: any;
}

export interface RemoveKeyClickCountAction extends AnyAction {
    type: KeySetterActionType.REMOVE_CLICKCOUNT;
}

export interface SetKeyfromAction extends AnyAction {
    type: KeySetterActionType.SET_FROM;
    from: any;
}

export interface RemoveKeyfromAction extends AnyAction {
    type: KeySetterActionType.REMOVE_FROM;
}

export type KeySetterAction = SetKeyAction | RemoveKeyAction | RemoveKeyNameAction | SetKeyNameAction
| RemoveKeyLoadingAction | SetKeyLoadingAction | SetKeyClickCounAction | RemoveKeyClickCountAction
| SetKeyfromAction | RemoveKeyfromAction; // Update to include remove key action

export interface KeySetterState {
    key: any;
    name: any;
    isLoading: any;
    countTimes: any;
    from: any;
}
