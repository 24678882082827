import { Field, Mutation } from '@tilework/opus';

/** @namespace TrouperPwa/Query/Registration/Query */

export class RegistrationQuery {
    getSendOtpMutation(username: string): Mutation<'sendOtp',
    { otp: string; status: string; secret: string; is_customer: boolean; interval: number }> {
        return new Mutation<'sendOtp', { otp: string; status: string; secret: string; is_customer: boolean;
            interval: number; }>('sendOtp')
            .addArgument('username', 'String!', username)
            .addField('otp')
            .addField('interval')
            .addField('status')
            .addField('secret')
            .addField('is_customer');
    }

    verifyOtpMutation(
        username: string,
        secret: string,
        otp: string,
        country_code?:string,
        countrydial_code?:string,
    ): Mutation<'verifyOtp',
        { status: boolean; message: string; token: string; profile_update: boolean;
            redirect_url: string; is_customer: boolean;is_signup_email: boolean;is_signup_mobile_no: boolean; } > {
        return new Mutation<'verifyOtp', { status: boolean; message: string; token: string;
            profile_update: boolean; redirect_url: string; is_customer: boolean;
            is_signup_email: boolean;is_signup_mobile_no: boolean; }>('verifyOtp')
            .addArgument('username', 'String!', username)
            .addArgument('otp', 'String!', otp)
            .addArgument('secret', 'String!', secret)
            .addArgument('country_code', 'String', country_code)
            .addArgument('countrydial_code', 'String', countrydial_code)
            .addField('status')
            .addField('message')
            .addField('token')
            .addField('profile_update')
            .addField('redirect_url')
            .addField('is_customer')
            .addField('is_signup_email')
            .addField('is_signup_mobile_no');
    }

    customerLoginMutation(username: string, password:string): Mutation<'customerLogin',
    { token: string; profile_update: boolean; redirect_url: string; is_customer: boolean;status:string; message:string }
    > {
        return new Mutation<'customerLogin',
        { token: string; profile_update: boolean; redirect_url: string; is_customer: boolean;status:string;
            message:string; }>('customerLogin')
            .addArgument('username', 'String!', username)
            .addArgument('password', 'String!', password)
            .addField('token')
            .addField('profile_update')
            .addField('redirect_url')
            .addField('is_customer')
            .addField('status')
            .addField('message');
    }

    _getCustomerField(): Field<'customer', any> {
        return new Field<'customer', any>('customer')
            .addFieldList(this._getCustomerFields());
    }

    _getCustomerInfoFields(): any {
        return [
            new Field<'primary_phone_number', string>('primary_phone_number'),
            // new Field<'secondary_phone_number', string>('secondary_phone_number'),
            // new Field<'profile_update', string>('profile_update'),
            // new Field<'token', string>('token'),
        ];
    }

    _getRegionField(): Field<'region', any> {
        return new Field<'region', any>('region')
            .addFieldList(this._getRegionFields());
    }

    _getRegionFields(): any {
        return [
            new Field<'region_code', string>('region_code'),
            new Field<'region', string>('region'),
        ];
    }

    _getAddressField(): Field<'addresses', any> {
        return new Field<'addresses', any>('addresses')
            .addFieldList(this._getAddressesFields());
    }

    _getAddressesFields(): any {
        return [
            new Field<'firstname', string>('firstname'),
            new Field<'middlename', string>('middlename'),
            new Field<'lastname', string>('lastname'),
            new Field<'street', string>('street'),
            new Field<'city', string>('city'),
            new Field<'postcode', string>('postcode'),
            new Field<'country_code', string>('country_code'),
            new Field<'telephone', string>('telephone'),
        ];
    }

    _getCustomerInfoField(): Field<'custom_info', any, true> {
        return new Field<'custom_info', any, true>('custom_info', true)
            .addFieldList(this._getCustomerInfoFields());
    }

    _getCustomerQueryInfoFields(): any {
        return [
            new Field<'primary_phone_number', string>('primary_phone_number'),
            // new Field<'secondary_phone_number', string>('secondary_phone_number'),
            new Field<'token', string>('token'),
            new Field<'profile_update', string>('profile_update'),
        ];
    }

    _getCustomerQueryInfoField(): Field<'custom_info', any, true> {
        return new Field<'custom_info', any, true>('custom_info', true)
            .addFieldList(this._getCustomerInfoFields());
    }

    _getCustomerFields(): any {
        return [
            new Field<'id', string>('id'),
            new Field<'firstname', string>('firstname'),
            new Field<'lastname', string>('lastname'),
            new Field<'email', string>('email'),
            new Field<'gender', string>('gender'),
            new Field<'countrydial_code', string>('countrydial_code'),
            new Field<'country_code', string>('country_code'),
            this._getCustomerInfoField(),
        ];
    }

    _getCustomerQueryField(): any {
        return [
            new Field<'id', number>('id'),
            new Field<'firstname', string>('firstname'),
            new Field<'lastname', string>('lastname'),
            new Field<'email', string>('email'),
            new Field<'middlename', string>('middlename'),
            new Field<'suffix', string>('suffix'),
            this._getCustomerQueryInfoField(),
            this._getAddressField(),
        ];
    }

    getCreateAccountMutation(options: any): Mutation<'updateCustomer', { customer: any }> {
        // const { customer, password } = options;

        return new Mutation<'updateCustomer', { customer: any }>('updateCustomer')
            .addArgument('input', 'CustomerInput!', options)
            .addField(this._getCustomerField());
    }

    getCountries(): any {
        return new Field('storeConfig')
            .addFieldList([
                new Field('allowed_countries').addFieldList([
                    'code',
                    'name',
                ]),
            ]);
    }

    getCustomerDetails(): any {
        return new Field('customer')
            .addFieldList([
                new Field<'id', string>('id'),
                new Field<'firstname', string>('firstname'),
                new Field<'middlename', string>('middlename'),
                new Field<'lastname', string>('lastname'),
                new Field<'id', string>('id'),
                new Field<'suffix', string>('suffix'),
                new Field<'email', string>('email'),
                new Field<'country_code', string>('country_code'),
                new Field<'countrydial_code', string>('countrydial_code'),
                new Field<'gender', string>('gender'),
                new Field('custom_info').addFieldList([
                    'primary_phone_number',
                    // 'secondary_phone_number',
                    'profile_update',
                    'is_signup_email',
                    'is_signup_mobile_no',
                ]),
                new Field('addresses').addFieldList([
                    new Field<'firstname', string>('firstname'),
                    new Field<'middlename', string>('middlename'),
                    new Field<'lastname', string>('lastname'),
                    new Field<'street', string>('street'),
                    new Field<'city', string>('city'),
                    new Field<'postcode', string>('postcode'),
                    new Field<'country_code', boolean>('country_code'),
                    new Field<'telephone', string>('telephone'),
                    new Field('region').addFieldList([
                        'region_code',
                        'region',
                    ]),
                ]),
            ]);
    }

    // resetPasswordEmail(email: string): Mutation<'requestPasswordResetEmail', boolean> {
    //     return new Mutation<'requestPasswordResetEmail', boolean>('requestPasswordResetEmail')
    //         .addArgument('email', 'String!', email);
    // }

    resetPasswordEmail(
        email: string,
    ): Mutation<'requestPasswordResetEmail', { success: string; email: string }> {
        return new Mutation<'requestPasswordResetEmail',
        { success: string; email: string }>('requestPasswordResetEmail')
            .addArgument('email', 'String!', email)
            .addField('success')
            .addField('email');
    }

    changeCustomerPasswordMutation(
        newPassword: string,
    ): Mutation<'changeCustomerPassword', { id: number; email: string }> {
        return new Mutation<'changeCustomerPassword', { id: number; email: string }>('changeCustomerPassword')
            .addArgument('newPassword', 'String!', newPassword)
            .addField('id')
            .addField('email');
    }
}

export default new RegistrationQuery();
