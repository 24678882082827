import {
    KeySetterActionType, RemoveKeyAction, RemoveKeyClickCountAction,
    RemoveKeyfromAction, RemoveKeyLoadingAction,
    RemoveKeyNameAction, SetKeyAction, SetKeyClickCounAction, SetKeyfromAction,
    SetKeyLoadingAction, SetKeyNameAction,
} from './RegisterPopup.type';

/**
 * Action to set the `key` value in the state.
 * @param {string} key - The key to be set in the state.
 * @returns {SetKeyAction} - The action to dispatch.
 * @namespace TrouperPwa/Store/RegisterPopup/Action/setKey */
export const setKey = (key: string): SetKeyAction => ({
    type: KeySetterActionType.SET_POPUPKEY,
    key,
});

/**
 * Action to remove the `key` from the state (reset it).
 * @returns {RemoveKeyAction} - The action to dispatch.
 * @namespace TrouperPwa/Store/RegisterPopup/Action/removeKey */
export const removeKey = (): RemoveKeyAction => ({
    type: KeySetterActionType.REMOVE_POPUPKEY,
});

/** @namespace TrouperPwa/Store/RegisterPopup/Action/setName */
export const setName = (name: string): SetKeyNameAction => ({
    type: KeySetterActionType.SET_POPUPKEYNAME,
    name,
});

/**
 * Action to remove the `key` from the state (reset it).
 * @returns {RemoveKeyAction} - The action to dispatch.
 * @namespace TrouperPwa/Store/RegisterPopup/Action/removeName */
export const removeName = (): RemoveKeyNameAction => ({
    type: KeySetterActionType.REMOVE_POPUPKEYNAME,
});

/** @namespace TrouperPwa/Store/RegisterPopup/Action/setLoading */
export const setLoading = (isLoading: string): SetKeyLoadingAction => ({
    type: KeySetterActionType.SET_ISLOADING,
    isLoading,
});

/**
 * Action to remove the `key` from the state (reset it).
 * @returns {RemoveKeyAction} - The action to dispatch.
 * @namespace TrouperPwa/Store/RegisterPopup/Action/removeLoading */
export const removeLoading = (): RemoveKeyLoadingAction => ({
    type: KeySetterActionType.REMOVE_ISLOADING,
});

/** @namespace TrouperPwa/Store/RegisterPopup/Action/setClickCount */
export const setClickCount = (countTimes: string): SetKeyClickCounAction => ({
    type: KeySetterActionType.SET_CLICKCOUNT,
    countTimes,
});

/**
 * Action to remove the `key` from the state (reset it).
 * @returns {RemoveKeyAction} - The action to dispatch.
 * @namespace TrouperPwa/Store/RegisterPopup/Action/removeClickCount */
export const removeClickCount = (): RemoveKeyClickCountAction => ({
    type: KeySetterActionType.REMOVE_CLICKCOUNT,
});

/** @namespace TrouperPwa/Store/RegisterPopup/Action/setFromCreate */
export const setFromCreate = (from: string): SetKeyfromAction => ({
    type: KeySetterActionType.SET_FROM,
    from,
});

/**
 * Action to remove the `key` from the state (reset it).
 * @returns {RemoveKeyAction} - The action to dispatch.
 * @namespace TrouperPwa/Store/RegisterPopup/Action/removeFromCreate */
export const removeFromCreate = (): RemoveKeyfromAction => ({
    type: KeySetterActionType.REMOVE_FROM,
});
